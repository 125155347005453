module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#00157D","theme_color":"#00157D","display":"minimal-ui","icon":"src/images/raise-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0f2edff08d3e04b5a2df09efff9e2dde"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
