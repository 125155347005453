import React from "react"
import { Provider } from "react-redux"

import createStore from "./src/state/createStore"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: any) => {
	// Instantiating store in `wrapRootElement` handler ensures:
	//  - there is fresh store for each SSR page
	//  - it will be called only once in browser, when React mounts
	const store = createStore()
	// uncomment this to get state updates in the console
	// store.subscribe(() => console.log(store.getState()))
	return <Provider store={store}>{element}</Provider>
}
