import { createStore as reduxCreateStore } from "redux"
import { categoryCollection } from "../data/categories"
import { expertiseCollection } from "../data/expertise"

const reducer = (state: any, action: any) => {
	if (action.type === `CHANGE_HEADER_COLOR`) {
		return Object.assign({}, state, {
			headerColor: action.payload,
		})
	}
	if (action.type === `SHOW_MOBILE_MENU`) {
		return Object.assign({}, state, {
			showMobileMenu: action.payload,
		})
	}
	if (action.type === `TOGGLE_REGISTER_FORM`) {
		return Object.assign({}, state, {
			showRegisterForm: !state.showRegisterForm,
			registrationStep: 0,
			registrationSubStep: 0,
		})
	}
	if (action.type === `GOTO_REGISTER_STEP`) {
		return Object.assign({}, state, {
			registrationStep: action.payload,
			registrationSubStep: 0,
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_ONE`) {
		const { name, age, city, role, experience } = action.payload
		return Object.assign({}, state, {
			editPrevious: false,
			registrationStep: state.editPrevious ? 8 : 2,
			registrationSubStep: 0,
			register: {
				...state.register,
				name,
				age,
				city,
				role,
				experience,
			},
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_TWO`) {
		const { categories } = action.payload
		return Object.assign({}, state, {
			registrationStep: 3,
			registrationSubStep: 0,
			register: {
				...state.register,
				categories,
			},
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_THREE`) {
		const { categories } = action.payload
		return Object.assign({}, state, {
			editPrevious: false,
			registrationStep: state.editPrevious ? 8 : 4,
			registrationSubStep: 0,
			register: {
				...state.register,
				categories,
			},
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_FOUR`) {
		const { expertises } = action.payload
		return Object.assign({}, state, {
			registrationStep: 5,
			registrationSubStep: 0,
			register: {
				...state.register,
				expertises,
			},
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_FIVE`) {
		const { expertises } = action.payload
		return Object.assign({}, state, {
			editPrevious: false,
			registrationStep: state.editPrevious ? 8 : 6,
			registrationSubStep: 0,
			register: {
				...state.register,
				expertises,
			},
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_SIX`) {
		const { email, website, instagram, facebook, youtube } = action.payload
		return Object.assign({}, state, {
			editPrevious: false,
			registrationStep: state.editPrevious ? 8 : 7,
			registrationSubStep: 0,
			register: {
				...state.register,
				email,
				website,
				instagram,
				facebook,
				youtube,
			},
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_SEVEN`) {
		const { services } = action.payload
		return Object.assign({}, state, {
			registrationStep: 8,
			registrationSubStep: 0,
			register: {
				...state.register,
				services: {
					...state.register.services,
					sessions: {
						...state.register.services.sessions,
						online: services.sessions.online,
						offline: services.sessions.offline,
						range: services.sessions.range,
					},
					courses: {
						...state.register.services.courses,
						online: services.courses.online,
						offline: services.courses.offline,
						range: services.courses.range,
					},
					seminars: {
						...state.register.services.seminars,
						online: services.seminars.online,
						offline: services.seminars.offline,
						range: services.seminars.range,
					},
					events: {
						...state.register.services.events,
						online: services.events.online,
						offline: services.events.offline,
						range: services.events.range,
					},
					retreats: {
						...state.register.services.retreats,
						online: services.retreats.online,
						offline: services.retreats.offline,
						range: services.retreats.range,
					},
					specialOffers: services.specialOffers,
				},
			},
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_EIGHT`) {
		return Object.assign({}, state, {
			registrationStep: 9,
			registrationSubStep: 0,
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_NINE`) {
		return Object.assign({}, state, {
			registrationStep: 10,
			registrationSubStep: 0,
			register: {
				...state.register,
				feedback: action.payload.feedback,
			},
		})
	}
	if (action.type === `SUBMIT_REGISTER_STEP_TEN`) {
		return Object.assign({}, initialState)
	}
	if (action.type === `SET_EDIT_PREVIOUS`) {
		return Object.assign({}, state, {
			editPrevious: action.payload,
		})
	}
	if (action.type === `SET_REGISTER_SUB_STEP`) {
		return Object.assign({}, state, {
			registrationSubStep: action.payload,
		})
	}
	return state
}

const initialState = {
	defaultHeaderColor: "#00157d",
	headerColor: "#fff",
	showMobileMenu: false,
	editPrevious: false,
	categoryCollection,
	expertiseCollection,
	showRegisterForm: false,
	registrationStep: 0,
	registrationSubStep: 0,
	register: {
		name: "",
		age: "",
		city: "",
		role: "",
		experience: "",
		categories: [],
		expertises: [],
		email: "",
		website: "",
		instagram: "",
		facebook: "",
		youtube: "",
		services: {
			sessions: {
				online: false,
				offline: false,
				range: "",
			},
			courses: {
				online: false,
				offline: false,
				range: "",
			},
			seminars: {
				online: false,
				offline: false,
				range: "",
			},
			events: {
				online: false,
				offline: false,
				range: "",
			},
			retreats: {
				online: false,
				offline: false,
				range: "",
			},
			specialOffers: "",
		},
		feedback: "",
	},
}

const createStore = () => reduxCreateStore(reducer, initialState)

export default createStore
