export const expertiseCollection = [
	{
		id: "physical-health",
		label: "Physical Health",
		description: "All body related symptoms",
		example: "Pain, Immune System, Digestive...",
		options: [
			{
				id: "pain-tension",
				label: "Pain / Tension",
			},
			{
				id: "blockages",
				label: "Blockages",
			},
			{
				id: "injuries",
				label: "Injuries",
			},
			{
				id: "immune-system",
				label: "Immune System",
			},
			{
				id: "circulatory",
				label: "Circulatory",
			},
			{
				id: "respiratory",
				label: "Respiratory",
			},
			{
				id: "digestive",
				label: "Digestive",
			},
			{
				id: "sexual-hormonal",
				label: "Sexual / Hormonal",
			},
		],
	},
	{
		id: "mental-health",
		label: "Mental Health",
		description: "Mental & emotional disorders",
		example: "Burnout, Anxiety, Depression...",
		options: [
			{
				id: "anxiety",
				label: "Anxiety",
			},
			{
				id: "phobia",
				label: "Phobia",
			},
			{
				id: "burnout",
				label: "Burnout",
			},
			{
				id: "depression",
				label: "Depression",
			},
			{
				id: "anger",
				label: "Anger",
			},
			{
				id: "stress-nervousness",
				label: "Stress / Nervousness",
			},
			{
				id: "loneliness-withdrawal",
				label: "Loneliness / Withdrawal",
			},
			{
				id: "cognitive-disorder",
				label: "Cognitive Disorder",
			},
		],
	},
	{
		id: "trauma",
		label: "Trauma",
		description: "Traumatic experiences",
		example: "Childhood, Accidents, Ancestral...",
		options: [
			{
				id: "childhood",
				label: "Childhood",
			},
			{
				id: "death-loss",
				label: "Death / Loss",
			},
			{
				id: "emotional-abuse",
				label: "Emotional Abuse",
			},
			{
				id: "mobbing",
				label: "Mobbing",
			},
			{
				id: "violence-force",
				label: "Violence/Force",
			},
			{
				id: "accidents",
				label: "Accidents",
			},
			{
				id: "separation",
				label: "Separation",
			},
			{
				id: "ancestral",
				label: "Ancestral",
			},
		],
	},
	{
		id: "behaviour",
		label: "Behaviour",
		description: "Behavioural patterns",
		example: "Addiction, Control Issues, Fixations...",
		options: [
			{
				id: "addiction",
				label: "Addiction",
			},
			{
				id: "attention-deficit",
				label: "Attention Deficit",
			},
			{
				id: "control-issues",
				label: "Control Issues",
			},
			{
				id: "fixations",
				label: "Fixations",
			},
			{
				id: "obsessive-compulsive",
				label: "Obsessive / Compulsive",
			},
			{
				id: "eating-disorders",
				label: "Eating Disorders",
			},
			{
				id: "social-disability",
				label: "Social Disability",
			},
			{
				id: "personality-disorders",
				label: "Personality Disorders",
			},
		],
	},
	{
		id: "Well-being",
		label: "Well-being",
		description: "Health, Wellness & Self Care",
		example: "Dietary, Massage, Fitness...",
		options: [
			{
				id: "massage-touch",
				label: "Massage / Touch",
			},
			{
				id: "relaxation",
				label: "Relaxation",
			},
			{
				id: "fitness-strength",
				label: "Fitness / Strength",
			},
			{
				id: "mobility-flow",
				label: "Mobility / Flow",
			},
			{
				id: "dietary-nutrition",
				label: "Dietary / Nutrition",
			},
			{
				id: "beauty-cosmetics",
				label: "Beauty / Cosmetics",
			},
			{
				id: "sensual-aromatic",
				label: "Sensual / Aromatic",
			},
			{
				id: "supplementation",
				label: "Supplementation",
			},
		],
	},
	{
		id: "relationship",
		label: "Relationship",
		description: "All matters of relations",
		example: "Compatibility, Intimacy, Conflict...",
		options: [
			{
				id: "compatibilty",
				label: "Compatibilty",
			},
			{
				id: "intimacy-sex",
				label: "Intimacy / Sex",
			},
			{
				id: "breakup-divorce",
				label: "Breakup / Divorce",
			},
			{
				id: "conflict-tension",
				label: "Conflict / Tension",
			},
			{
				id: "jealousy-control",
				label: "Jealousy / Control",
			},
			{
				id: "communication",
				label: "Communication",
			},
			{
				id: "acceptance-trust",
				label: "Acceptance / Trust",
			},
			{
				id: "family-relations",
				label: "Family Relations",
			},
		],
	},
	{
		id: "consulting",
		label: "Consulting",
		description: "Life coaching & guidance",
		example: "Self-Image, Purpose, Decision Making",
		options: [
			{
				id: "self-image-self-love",
				label: "Self-Image / Self-Love",
			},
			{
				id: "purpose-discovery",
				label: "Purpose / Discovery",
			},
			{
				id: "self-development",
				label: "Self-Development",
			},
			{
				id: "creative-expression",
				label: "Creative Expression",
			},
			{
				id: "shadow-work",
				label: "Shadow Work",
			},
			{
				id: "entrepreneurial",
				label: "Entrepreneurial",
			},
			{
				id: "decision-making",
				label: "Decision Making",
			},
			{
				id: "motivation-drive",
				label: "Motivation / Drive",
			},
		],
	},
	{
		id: "higher-self",
		label: "Higher Self",
		description: "Metaphysical & mystic rituals",
		example: "Activations, Karmic, Past-Life...",
		options: [
			{
				id: "karmic-healing",
				label: "Karmic Healing",
			},
			{
				id: "aura-energy-healing",
				label: "Aura / Energy Healing",
			},
			{
				id: "past-life",
				label: "Past-Life",
			},
			{
				id: "ancestral-lineage",
				label: "Ancestral / Lineage",
			},
			{
				id: "energy-healing",
				label: "Energy Healing",
			},
			{
				id: "activations",
				label: "Activations",
			},
			{
				id: "channeling",
				label: "Channeling",
			},
			{
				id: "clairvoyance",
				label: "Clairvoyance",
			},
		],
	},
]
