export const categoryCollection = [
	{
		id: "activity",
		label: "Activity",
		icon: "IconActivity",
		description: "Physical activity courses",
		example: "Yoga, Qi-Gong, Mobility...",
		options: [
			{
				id: "yoga",
				label: "Yoga",
			},
			{
				id: "qi-gong-tai-chi",
				label: "Qi-Gong / Tai-Chi",
			},
			{
				id: "pilates",
				label: "Pilates",
			},
			{
				id: "mobility",
				label: "Mobility",
			},
			{
				id: "breathwork",
				label: "Breathwork",
			},
			{
				id: "tensegrity",
				label: "Tensegrity",
			},
			{
				id: "dance-movement",
				label: "Dance / Movement",
			},
		],
	},
	{
		id: "bodywork",
		label: "Bodywork",
		icon: "IconBodywork",
		description: "Physical therapy with touch",
		example: "Osteopathy, Shiatsu, Rolfing...",
		options: [
			{
				id: "somatic-bodywork",
				label: "Somatic Bodywork",
			},
			{
				id: "osteopathy",
				label: "Osteopathy",
			},
			{
				id: "deep-tissue",
				label: "Deep Tissue",
			},
			{
				id: "shiatsu",
				label: "Shiatsu",
			},
			{
				id: "thai-massage",
				label: "Thai Massage",
			},
			{
				id: "craniosacral",
				label: "Craniosacral",
			},
			{
				id: "accupressure",
				label: "Accupressure",
			},
		],
	},
	{
		id: "remedy",
		label: "Remedy",
		icon: "IconHealth",
		description: "All natural medicine work",
		example: "Naturopathy, TCM, Vedic...",
		options: [
			{
				id: "naturopathy",
				label: "Naturopathy",
			},
			{
				id: "tcm",
				label: "TCM",
			},
			{
				id: "vedic",
				label: "Vedic",
			},
			{
				id: "accupuncture",
				label: "Accupuncture",
			},
			{
				id: "supplementation",
				label: "Supplementation",
			},
			{
				id: "dietary",
				label: "Dietary",
			},
		],
	},
	{
		id: "meditation",
		label: "Meditation",
		icon: "IconMeditation",
		description: "All meditation styles",
		example: "Mantra, Vipassana, Guided...",
		options: [
			{
				id: "mantra",
				label: "Mantra",
			},
			{
				id: "vipassana",
				label: "Vipassana",
			},
			{
				id: "zen",
				label: "Zen",
			},
			{
				id: "awareness",
				label: "Awareness",
			},
			{
				id: "mindfulness",
				label: "Mindfulness",
			},
			{
				id: "sound-journey",
				label: "Sound Journey",
			},
			{
				id: "guided",
				label: "Guided",
			},
		],
	},
	{
		id: "guidance",
		label: "Guidance",
		icon: "IconGuidance",
		description: "Different consultation sessions",
		example: "Coaching, Astrology, Therapy...",
		options: [
			{
				id: "life-coaching",
				label: "Life Coaching",
			},
			{
				id: "psychotherapy",
				label: "Psychotherapy",
			},
			{
				id: "astrology",
				label: "Astrology",
			},
			{
				id: "human-design",
				label: "Human Design",
			},
			{
				id: "relationship",
				label: "Relationship",
			},
			{
				id: "nutrition",
				label: "Nutrition",
			},
			{
				id: "gender-based",
				label: "Gender based",
			},
		],
	},
	{
		id: "perception",
		label: "Perception",
		icon: "IconSensory",
		description: "Sensory perceptions methods",
		example: "Sound, Aroma Therapy, Hypnosis...",
		options: [
			{
				id: "sound-healing",
				label: "Sound Healing",
			},
			{
				id: "hypnotherapy",
				label: "Hypnotherapy",
			},
			{
				id: "holotropic-breath",
				label: "Holotropic Breath",
			},
			{
				id: "aroma-therapy",
				label: "Aroma Therapy",
			},
			{
				id: "theta-healing",
				label: "Theta Healing",
			},
			{
				id: "psychedlics",
				label: "Psychedlics",
			},
			{
				id: "neurotuning",
				label: "Neurotuning",
			},
		],
	},
	{
		id: "psychic",
		label: "Psychic",
		icon: "IconPsychic",
		description: "Connecting to higher planes",
		example: "PLR, Angelic Work, Channeling...",
		options: [
			{
				id: "ancestral",
				label: "Ancestral",
			},
			{
				id: "past-life",
				label: "Past Life",
			},
			{
				id: "remote-viewing",
				label: "Remote Viewing",
			},
			{
				id: "medium",
				label: "Medium",
			},
			{
				id: "clairvoyance",
				label: "Clairvoyance",
			},
			{
				id: "angelic-work",
				label: "Angelic Work",
			},
		],
	},
	{
		id: "energy",
		label: "Energy",
		icon: "IconEnergy",
		description: "Energetic Healing",
		example: "Reiki, Constellations, Cleansing...",
		options: [
			{
				id: "reiki",
				label: "Reiki",
			},
			{
				id: "quantum-healing",
				label: "Quantum Healing",
			},
			{
				id: "constellations",
				label: "Constellations",
			},
			{
				id: "chakra-healing",
				label: "Chakra Healing",
			},
			{
				id: "aura-cleansing",
				label: "Aura Cleansing",
			},
			{
				id: "crystal-work",
				label: "Crystal Work",
			},
			{
				id: "smudging",
				label: "Smudging",
			},
		],
	},
	{
		id: "mystic",
		label: "Mystic",
		icon: "IconMystic",
		description: "Mystic Arts & Rituals",
		example: "Tarot, Shamanism, Kabbalah...",
		options: [
			{
				id: "shamanism",
				label: "Shamanism",
			},
			{
				id: "tarot",
				label: "Tarot",
			},
			{
				id: "mystery-schools",
				label: "Mystery Schools",
			},
			{
				id: "astral-travel",
				label: "Astral Travel",
			},
			{
				id: "numerology",
				label: "Numerology",
			},
			{
				id: "shadow-work",
				label: "Shadow Work",
			},
			{
				id: "soul-journeys",
				label: "Soul Journeys",
			},
		],
	},
]
